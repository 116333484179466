import Vue from "vue";
import Header from "@/views/Admin/Commons/Header.vue";
import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
import Alertize from "@/components/Alertize.vue";
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";
import IFrame from "@/views/Admin/Dashboard/Report/Iframe.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "OverviewLineItem",
	props: {},
	components: { Header, CreateTabs, Alertize, IFrame },
	data: () => ({
		title: "ReportLogic",
		currentTab: 0,
		expanded: false,
		src: null
	}),
	async created() {},
	async mounted() {
		this.$nextTick(async () => {
			// await this.handlerLoadPage();
		});

		this.getSrc();
	},
	computed: {
		...mapGetters("loading", ["isLoading"]),
		...mapGetters("profile", ["getProfileData"]),
		getItems() {
			return this.items;
		},
		hasItems() {
			return !isEmpty(this.items);
		},
		getItemSelected() {
			const data = this.items.find(
				(i: { key: any }) => i.key === this.currentTab
			);
			return data
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("dashboard", ["getLookerUrl"]),

		async getSrc() {
			const data = await this.getLookerUrl()

			this.src = data;
		},

		handlerSelectedTabIndex(index: any) {
			this.currentTab = index;
		},
		handlerExpanded(event: any) {
			this.expanded = !this.expanded;
		},
		// async handlerLoadPage(){
		// 	await this.setLoadingData(TypeLoading.loading);
		// 	const reportList: Array<any> = await this.getSrc();
		// 	this.items = reportList;
		// 	await this.setLoadingData();
		// }
	},
	watch: {
		async "getProfileData"(val){
			await this.setLoadingData(TypeLoading.loading);
			await this.getSrc();
			await this.setLoadingData();
		}
	},
});


import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
	name: "Iframe",
	props: {
		src: {
			type: String,
			required: true,
		},
		width: {
			type: String,
			default: "100%",
		},
		height: {
			type: String,
			default: "1200px",
		},
		iframe_id: {
			type: String,
			default: Math.ceil(Date.now() / 1000).toString(),
		},
	},
	components: {},
	data: () => ({
		loaded: false,
	}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters("loading", ["isLoading"]),
	},
	methods: {
		handlerError(event: any) {
			console.error("IFrame::handlerError", { event });
			this.loaded = true;
		},
		handlerLoad(event: any) {
			this.loaded = true;
		},
	},
	watch: {
		src(val) {
			this.loaded = false;
		},
	},
});
